@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap-icons";
@import 'styles/variables';
@import "bootstrap/scss/bootstrap";

@import 'styles/root';
@import 'styles/font';
@import 'styles/button';
@import 'styles/icons';
@import 'styles/spaces';
@import 'styles/sizes';
@import 'styles/grid';
@import 'styles/input';
@import 'styles/common';
@import 'styles/modal';
@import 'styles/pagination';

* {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #babac0;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  scrollbar-width: thin;
}

// 버튼 디자인
.btn-strip {
  background-color: var(--blue-80);
  color: var(--white);
  border-color: var(--blue-80);
  font-size: var(--f14);
  padding: 1rem;
  border-radius: 0.778rem;

  &:hover {
    background-color: var(--blue-80);
    color: var(--white);
    border-color: var(--blue-80);
    border-radius: 0.778rem;
  }
}

.btn-strip-inv {
  background-color: white;
  border-color: var(--blue-80);
  color: var(--blue-80);
  padding: 1rem;
  border-radius: 0.778rem;
  font-size: var(--f14);

  &:hover {
    background-color: var(--blue-80);
    color: var(--white);
    border-color: var(--blue-80);
    border-radius: 0.778rem;
  }
}

.full-height-page {
  min-height: var(--page-full-height);
}

#root {
  // 필요에 의해 height가 100% 이어야 할 때 적용되도록 값 상속 하게 함
  height: inherit;
}

// 높이 100%
.h100 {
  height: 100%;
}

.center {
  text-align: center;
}

@function neg($val) {
  @return $val * -1;
}
