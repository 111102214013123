@import 'styles/mixin.scss';

#button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  border: 0;
  border-radius: var(--f8);
  background-color: var(--gray-10);

  &:hover {
    opacity: 0.2;
  }
}

#button_active {
  @extend #button;
  background-color: var(--blue-80);
}

.frame {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--f16) var(--f14) var(--f16) var(--f24);
}

.sub_frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.desc {
  @include text(var(--f16),600);
  margin-bottom: var(--f12);
  font-family: 'Pretendard';
  color: var(--gray-3090);
  line-height: 21px!important;
}

.desc_active {
  @extend .desc;
  color: var(--gray-10);
}

.count {
  @include text(var(--f28),800);
  color: var(--default);
  line-height: 32px!important;
}

.count_active {
  @extend .count;
  color: var(--gray-10);
}

.icon {
  @extend .icon_active;
  transform: rotate(-90deg);
}

.icon_active {
  width: var(--f18);
  height: var(--f18);
}

@media screen and (max-width: 1680px) {

  .frame{
    padding: var(--f16) var(--f14) var(--f16) var(--f24);
  }

  .desc {
    @include text(var(--f14),600);
    margin-bottom: var(--f10);
  }

  .count {
    @include text(var(--f24),800);
  }
}
