/* DropDown Wrapper */
.dropdown_wrapper {
  position: relative;
}
.dropdown_element {
  visibility: visible;
  filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
  transition: visibility 0.15s ease-out;
  animation: fadeIn 0.15s ease-out;
  position: absolute;
  z-index: 999999999;
  // min-width: 100%;
}
.fit_element {
  inset: 0!important;
}
.dropdown_element_hidden {
  visibility: hidden;
  transition: visibility 0.15s ease-out;
  animation: fadeOut 0.15s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* DropDown Components */

.dropdown_container {
  background: var(--white);
  border: 1px solid var(--gray-30);
  border-radius: var(--f4);
}
.dropdown_menu {
  width:100%;
  background: var(--white);
  border-radius: var(--f4);
  overflow-y: auto;
}

.dropdown_menu_item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: var(--f7) var(--f10);
  font-size: var(--f12);
}

.dropdown_menu_item:hover {
  background-color: var(--gray-10);
}

.dropdown_menu_title {
  font-size: var(--f12);
}

.dropdown_menu_sub_title {
  width: 100%;
  display: flex;
  justify-content: 'flex-start';
  font-size: var(--f12);
  color: var(--gray-50);
  margin: var(--f10);
}

.dropdown_search_input {
  width: 100%;
  border: none;
  background: #f9fafb;
  font-size: 12px;
  padding: 6px;
  border-bottom: 1px solid #d2d7dc;
}
.search_list_item_text {
  font-size: var(--f12);
  margin: 0;
  color: var(--gray-90);
}

/* Progress Dropdown Menu */
.badge_list {
  padding: var(--f5) 0;
}
.badge_item {
  padding: 0 var(--f7);
}
