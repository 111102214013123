@import 'styles/mixin.scss';
// ????? css??
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: none;

  z-index: 999999;
}

/* common style */
%modal {
  color: var(--default-text);
  background: var(--white);
  border-radius: var(--f12);
  filter: drop-shadow(0px 1px 20px rgba(0, 0, 0, 0.2));
}

%modal_text {
  @include text(var(--f18),700);
  color: var(--default-text);
  white-space: pre-wrap;
}

%modal_button {
  @include text(var(--f14),700);
  background: var(--blue-90);
  border-radius: var(--f8);
  border: 0;
  padding: var(--f12) var(--f34);

  text-align: center;
  vertical-align: center;
  color: var(--white);

  &:hover {
    opacity: 0.8;
  }
}

%modal_title {
  @include text(var(--f24),700);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--gray-90);
}

%button_group {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--f10);
  white-space: nowrap;
}

.modal {
  @extend %modal;
}
.modal_title {
  @extend %modal_title;
}

.modal_text {
  @extend %modal_text;
}


/* Modal Wrapper */
.modal_container{
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.dimmed {
  background: rgba(0, 0, 0, 0.4);
  z-index: 999999;
}

.hideBackdrop {
  background: transparent!important;
}

.modal_component {
  display: inline-block;
  overflow-y: visible;
  overflow-x: hidden;
}

/*Modal Components*/

.modal_label{
  @include text(var(--f13),600);
  color: var(--gray-90);
}

/* Confirm Modal */

.confirm_modal__sub_text {
  font-size: var(--f14);
  color: var(--gray-50);
  margin-top: var(--f20);
  white-space: pre-wrap;
}

/* Form Modal*/

.form_modal__content {
  display: flex;
  flex-direction: column;
  padding-top: var(--f10);
}

/* Status Modal */
.status_modal__text {
  @extend %modal_text;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: var(--f18);
  margin-bottom: 0;
  white-space: pre-wrap;
}

/* CheckBox Modal */

.checkbox_modal {
  @extend %modal;
  width: var(--f240);
  padding: var(--f10);
  border-radius: var(--f4);
}
.checkbox_modal__title {
  @extend .modal_title;
  font-size: var(--f12);
  padding: 0;
}

/* Search List Modal */
.search_list_modal {
  @extend %modal;
  border: 1px solid var(--gray-30);
  border-radius: var(--f4);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: var(--f10);
  max-height: 428px;
}

.search_list_small {
  width: var(--f195);
}
.search_list_medium {
  width: var(--f240);
}
.search_list_large {
  width: var(--f493);
}

.hide_shadow {
  filter: none;
}

.search_list_description {
  @include text(var(--f12),500);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-left: var(--f10);
  padding: var(--f5) 0;
  color: var(--gray-50);
}

.hidden {
  display: none;
}
.search_list_items {
  width: 100%;
  overflow-y: auto;
  @include hideScrollBar();
}

.profile_and_text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: var(--f10);
  column-gap: 8px;
}

.no_list {
  display: flex;
  justify-content: center;
  font-size: var(--f12);
  color: var(--gray-90);
  margin-top: var(--f10);
}

.search_list_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  padding: var(--f10);
  margin-top: var(--f8);
  &:hover{
    background-color: var(--gray-10);
  }
}

.search_list_item_text {
  font-size: var(--f12);
  margin: 0;
  color: var(--gray-90);
}

.checkbox {
  display: flex;
  justify-content: flex-end;
  margin-right: var(--f10);

  input {
    display: none;
  }

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--f18);
    height: var(--f18);
    border: var(--f2) solid var(--gray-30);
    border-radius: var(--f4);
  }

  input:checked ~ label {
    background-color: var(--default);
    border: none;
  }
}

/* Modal Container */

.container {
  @extend %modal;
  padding: var(--f10);
  overflow-y: auto;
  overflow-x: hidden;
  @include hideScrollBar();
}

.ss {
  width:var(--f196);
} //super small
.xs {
  width: var(--f223);
} //extra small
.sm {
  width: var(--f250);
} //small
.md {
  width: var(--f482);
} //medium
.lg {
  width: var(--f613);
} //large
.xl {
  width: var(--f742);
} //extra large
.wd {
  width: var(--f820);
} //wide
.sw {
  width: 80vw;
} //super wide

.info_container {
  padding: var(--f45);
}
.confirm_container {
  padding: var(--f52) 0 var(--f36) 0;
  align-items: center;
}
.alert_container {
  min-height: var(--f204);
  text-align: center;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}
.project_container {
  padding-top: var(--f43);
  padding-bottom: var(--f43);
  padding-left: var(--f42);
  padding-right: var(--f42);
}

.form_sm {
  border-radius: var(--f4);
  padding: var(--f20) var(--f32) var(--f37) var(--f20);
}
.form_lg {
  padding: var(--f43) var(--f55) var(--f37) var(--f45);
}
.form_xl{
  padding: var(--f43) var(--f55) var(--f37) var(--f45);
}
.form_sw {
  padding: var(--f43) var(--f45) var(--f37) var(--f45);
}

.btns_right{
  justify-content: end;
}

/* Modal Title */
.title_small {
  font-size: var(--f16);
}
.title_medium {
  font-size: var(--f18);
}
.title_large{
  font-size: var(--f24);
}

.modal_title_container {
  display: flex;
  justify-content: space-between;
}
.modal_title_button {
  background: none;
  border: none;
}

/* Modal Sub Title */

.modal_sub_title_container {
  width: 100%;
  display: flex;
}
.modal_sub_title {
  @include text(var(--f16), 500);
  color: var(--gray-90);
  align-self: flex-start;
}


.modal_input_title {
  font-size: var(--f12);
  font-weight: 600;
  align-self: start;
  margin: var(--f10) 0;
}
.modal_input {
  @include text(var(--f12),500);
  width: 100%;
  background-color: var(--gray-10);
  border: none;
  border-radius: var(--f4);
  padding: var(--f10);
}
.modal_textarea {
  resize: none;
  height: var(--f136);
}
.modal_button_group {
  height: 20%;
}
.button_group {
  @extend %button_group;
  margin-top: var(--f20);
}
.confirm_button {
  @extend %modal_button;
}
.cancel_button {
  @extend %modal_button;
  background: var(--white);
  border: var(--f1) solid var(--gray-30);
  border-radius: var(--f8);
  color: var(--gray-90);
}

.single_button_group {
  margin-top: var(--f20);
  text-align: center;
}


.error_container{
  position:relative;
}
.error_message {
  @include text(var(--f12),500);
  color: var(--red-90);
  position:absolute;
  right:0;
}

.dropdown_input_container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
  padding: 0 var(--f10);
  border: 0;
  background: var(--gray-10);
  border-radius: var(--f4);
}

.dropdown_input {
  @include text(var(--f12), 400);
  width:100%;
  color: var(--gray-90);
  border:none!important;
  outline: 0!important;
  background: none;
  cursor: pointer;

  &::placeholder {
    @include text(var(--f12), 400);
    color: var(--gray-50);
    display:flex;
    align-items: center;
  }
}

.dropdown_arrow{
  width: var(--f12);
}

.progress_modal_container {
  padding: var(--f70);
}
