@import 'styles/mixin.scss';


.table_fix_head {
  overflow-y: auto;
}

.table_fix_head thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 0;
  background: white;
}

.hide_scroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

.table_thead_tr {
  position: relative;
  white-space: nowrap;
  z-index: 999;

  th {
    @include text(var(--f14),700);
    white-space: nowrap;
    padding-bottom: 0;
    padding-top: 0;
    text-align: center;
    color: var(--default-text);
    border-width: 0px!important;
  }

  .short_width {
    width: var(--f45);
  }

  .short_middle_width {
    width: var(--f100);
  }

  .middle_width {
    width: var(--f200);
  }
}

.scene_td_text {
  @include text(var(--f14),500);
  margin-bottom: 0px;
  text-align: center;
  color: var(--default-text);
  @include ellipsis();
}

.table_tbody_tr {
  /* bg */
  height: var(--f54);
  border-radius: var(--f14);
  overflow: visible;
  // background: var(--gray-20);

  td {
    @include text(var(--f14),500);
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    color: var(--default-text);
    border-width: 0px;

    div {
      .main_title {
        @include text(var(--f14),700);
        color: var(--default-text);
        padding: 0;
        margin: 0;
      }
      .sub_title {
        @include text(var(--f12),500);
        color: var(--gray-50);
        padding: 0;
        margin: 0;
      }
    }
  }

  td:nth-child(1) {
    border-top-left-radius: var(--f14);
    border-bottom-left-radius: var(--f14);
  }

  td:last-child {
    border-top-right-radius: var(--f14);
    border-bottom-right-radius: var(--f14);
  }

  &:hover {
    // background: #F0F0FA;
    border-radius: var(--f14);
  }
  cursor: pointer;

}

.table_bordered .table_tbody_tr {
  td {
    border-width: 1px 0px;
  }

  td:nth-child(1) {
    border-left-width: 1px;
  }

  td:last-child {
    border-right-width: 1px;
  }
}

.tr_checked{
  background: var(--white-20);
}

.bottom_navigation {
  display: grid;
  grid-template-columns: 1fr 0fr;

  .bottom_episodes {
    display: flex;
    flex-direction: row;
  }

  .bottom_pagination {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
