@import './Frame.module.scss';

#header {
  /* Rectangle 275 */
  position: fixed;
  left: 0rem;
  top: 0rem;
  width: 100%;
  height: $header_height;
  background-color: white;
  box-shadow: 0rem var(--f5) var(--f10) rgba(85, 95, 105, 0.2);
  z-index: 1000;

  .navbar {
    padding: 0;

    .logo_wrapper {
      display: flex;
      align-items: center;
      height: $header_height;
      width: $leftmenu_width;
      padding: 0 22px;
      text-align: left;
      border-right: 1px solid var(--blue-gray-100);
    }

    .groupMenu {
      min-width: 246px;

      .groupItem {
        display: flex;
        align-items: center;
        column-gap: 12px;
        padding: 10px 22px;
      }

      .groupImage {
        width: 42px;
        min-width: 42px;
        height: 42px;
        object-fit: cover;
        background-color: var(--blue-gray-100);
      }
    }

    .profileMenu {
      min-width: 198px;
      left: auto;
      right: 0;

      .profileItem {
        display: flex;
        align-items: center;
        column-gap: 12px;
        padding: 6px 12px;
      }
    }

  }
  .usage_container {
    width: $leftmenu_width;
  }

  .progress_container {
    width: $leftmenu_width;
  }
}
