.root {
  border-radius: 14px;
  border: 1px solid #d2d7dc;
  background: #f9fafb;
  padding: 24px 27px;
  overflow-y: auto;
}

.input {
  border: 0 !important;
  outline: 0 !important;
  background-color: transparent;
  font-size: 20px;
  padding: 0;
  width: 100%;
}

.tag {
  display: flex;
  align-items: center;
  column-gap: 8px;
  white-space: nowrap;
  background-color: #F0F0FA;
  border-radius: 8px;
  padding: 6px 10px;

  svg {
    stroke: #6E6F73;
    fill: #6E6F73;
  }
}
