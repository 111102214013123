.modal-header {
  border: 0;
}

.modal-footer {
  border: 0;
  column-gap: 10px;
}

.modal-normal {
  max-width: 742px;

  .modal-header {
    padding: 43px 45px 0 45px;
  }

  .modal-body {
    padding: 16px 45px;
    min-height: 300px;
  }

  .modal-footer {
    padding: 0 45px 37px 45px;
  }
}

.modal-small {
  max-width: 482px;
}

.modal-xsmall {
  max-width: 260px;

  .modal-header {
    padding: 24px 22px 0 22px;
  }

  .modal-body {
    padding: 20px 22px 34px 22px;
  }

  .modal-footer {
    padding: 0 22px 32px 22px;
    flex-wrap: nowrap;
  }
}
