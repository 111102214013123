.search_lists{
  display:flex;
}
.modal_list{
  width:100%;
  display:flex;
  justify-content: space-between;
  // flex-wrap: wrap;
  gap: var(--f10);
}
.preview{
  padding: 0 10px;
  margin-top: 30px;
}
.strips{
  max-height: var(--f172);
  overflow: auto;
}
.strips::-webkit-scrollbar{
  display: none;
}

