.root {
  position: relative;

  .input {
    height: 42px;
    font-size: 14px;
  }

  .dropdown {
    display: none;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border: 1px solid var(--blue-gray-200, #d2d7dc);
    border-top-width: 0;
    z-index: 10;
    overflow: hidden;

    ul {
      list-style: none;
      padding: 8px 0;
      margin: 0;
      max-height: 210px;
      overflow-y: auto;
      li {
        padding: 6px 20px;
        display: flex;
        align-items: center;
        column-gap: 12px;
        cursor: pointer;

        &:hover, &.itemHover {
          background-color: #d9d9d9;
        }

        &:first-child {
          margin-top: 8px;
        }

        &:last-child {
          margin-bottom: 8px;
        }
      }

      .addIcon {
        background-color: #3ac8b4;
        color: white;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
      }
    }
  }

  &.open {
    .input {
      border-bottom: 0 !important;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .dropdown {
      display: block;
      position: absolute;
      top: 42px;
      left: 0;
      right: 0;
      background-color: #fff;
      box-shadow: 0px 1px 20px 0px rgba(53, 58, 67, 0.2);
    }
  }
}
