@import 'styles/mixin.scss';

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--f13);
}

.item_text {
  @include text(var(--f12), 500);
  color: var(--gray-50);
  margin-bottom: 0;
  margin-right: var(--f32);
}

.label {
  @include text(var(--f14), 700);
  text-align: right;
  color: var(--gray-50);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.checklist {
  display: flex;
}
