@import './Frame.module.scss';
@import './Footer.module.scss';

#left_menu {
  position: fixed;
  top: $header_height;
  left: 0;
  overflow-y: auto;
  width: $leftmenu_width;
  max-width: $leftmenu_width;
  background-color: #fff;
  border-right: 1px solid var(--blue-gray-100);
  color: var(--gray-90);
  height: calc(100vh - $header_height - $footer_height);
  transition: all 0.2s ease-in-out;

  .companyWrapper {
    height: 112px;
  }

  .companyLogo {
    width: 42px;
    height: 42px;
  }

  .collapseBtn {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
  }

  .menuContent {
    padding: 32px 0 0 0;
  }

  .role {
    color: var(--blue-80);
    background-color: var(--blue-8010);
    border-radius: var(--f4);
    padding: 0 var(--f6);
    font-weight: 700;
    width: fit-content;
  }

  &.collapsed {
    max-width: $collapsed_leftmenu_width;
    min-width: $collapsed_leftmenu_width;
    transition: all 0.2s ease-in-out;
  }
}

.menuList {
  padding: 0;
  list-style: none;
  margin: 0;

  li {
    padding: 20px 8px 5px 27px;
  }

  .menuItem {
    display: flex;
    align-items: center;
    column-gap: 19px;
    height: 44px;
    padding: 8px 8px 8px 27px;
    cursor: pointer;
    flex-wrap: nowrap;

    &:hover {
      background-color: var(--gray-20);
    }
  }

  .activedMenu {
    color: var(--default);

    svg {
      fill: var(--default);
      stroke: var(--default);
    }
  }

  a {
    color: var(--gray-90);
    text-decoration-line: none;
    padding-left: var(--f20);

    &:hover {
      color: var(--default);
    }
  }

  .active {
    font-style: normal;
    font-weight: 600;
    font-size: var(--f16);
    line-height: 166%;

    border-radius: 0px var(--f10) var(--f10) 0px;
    cursor: pointer;
    background-color: var(--blue-8010);
    color: var(--default);

    a {
      color: var(--default);

      &:hover {
        color: var(--default);
      }
    }
  }
}

.title {
  margin-left: var(--f28);
  margin-top: 1.25rem;
  margin-bottom: var(--f3);
}
