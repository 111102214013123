@import 'styles/mixin.scss';

.page_buttons_group {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;

  .btn {
    /* Auto layout */
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: var(--f28);
    height: var(--f28);
    border: var(--f1) solid #D2D7DC;
    border-radius: var(--f6);
    margin-right: 4.7px;
    background: var(--white);

    span {
      @include text(var(--f16),500);
      width: var(--f10);
      height: 27px;
      text-align: center;
      vertical-align: center;
      color: var(--black);
    }

    &:hover {
      opacity: 0.6;
    }

  }
  .active {
    background: #868FD3;
    span {
      color: #FFFFFF;
    }

  }
}
