.svg-icon-default {
  stroke: $gray-800;
  fill: $gray-800;
}

.svg-icon-primary {
  stroke: $primary;
  fill: $primary;
}

.svg-icon-white {
  stroke: $white;
  fill: $white;
}

.svg-icon-danger {
  stroke: $danger;
  fill: $danger;
}

.svg-icon-bluegray-200 {
  stroke: $blue-gray-200;
  fill: $blue-gray-200;
}

.svg-icon-bluegray-300 {
  stroke: $blue-gray-300;
  fill: $blue-gray-300;
}

.svg-icon-red-50 {
  stroke: $red-50;
  fill: $red-50;
}

.svg-icon-chip {
  stroke: var(--chip-1);
  fill: var(--chip-1);
}

.svg-icon-yellow {
  stroke: #ffd84f;
  fill: #ffd84f;
}
