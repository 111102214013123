@each $name, $space in $custom-spacers {
  .m-#{$name} {
    margin: #{$space} !important;
  }

  .mx-#{$name} {
    margin-left: #{$space} !important;
    margin-right: #{$space} !important;
  }

  .my-#{$name} {
    margin-top: #{$space} !important;
    margin-bottom: #{$space} !important;
  }

  .mt-#{$name} {
    margin-top: #{$space} !important;
  }

  .mb-#{$name} {
    margin-bottom: #{$space} !important;
  }

  .ms-#{$name} {
    margin-left: #{$space} !important;
  }

  .me-#{$name} {
    margin-right: #{$space} !important;
  }

  .pt-#{$name} {
    padding-top: #{$space} !important;
  }

  .pb-#{$name} {
    padding-bottom: #{$space} !important;
  }

  .ps-#{$name} {
    padding-left: #{$space} !important;
  }

  .pe-#{$name} {
    padding-right: #{$space} !important;
  }

  .px-#{$name} {
    padding-left: #{$space} !important;
    padding-right: #{$space} !important;
  }

  .py-#{$name} {
    padding-top: #{$space} !important;
    padding-bottom: #{$space} !important;
  }

  .p-#{$name} {
    padding: #{$space} !important;
  }

  .gap-#{$name} {
    row-gap: #{$space} !important;
    column-gap: #{$space} !important;
  }

  .gap-x-#{$name} {
    column-gap: #{$space} !important;
  }

  .gap-y-#{$name} {
    row-gap: #{$space} !important;
  }

  .top-#{$name} {
    top: #{$space} !important;
  }

  .bottom-#{$name} {
    bottom: #{$space} !important;
  }

  .right-#{$name} {
    right: #{$space} !important;
  }

  .left-#{$name} {
    left: #{$space} !important;
  }
}

.p-2\.5 {padding: 10px;}

.pt-21\.5 { padding-top: 86px; }
.pt-26 { padding-top: 104px; }

.px-4\.5 { padding-left: 18px; padding-right: 18px; }
.py-45 { padding-top: 180px; padding-bottom: 180px;}

.gap-2\.5 { column-gap: 10px; row-gap: 10px; }