.checkbox{
  input {
    display: none;
  }

  label {
    width: var(--f18);
    height: var(--f18);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--gray-30);
    border-radius: var(--f4);
    cursor:pointer;
  }

  input:checked ~ label {
    background-color: var(--default);
    border: none;
  }
}

/* Checkbox List */

.checkbox_list_container{
  
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .checkbox_list{
    width: 100%;
    padding: 0;
  
    li{
      list-style: none;
      padding: var(--f10) 0;
      font-size: var(--f12);
      
      .checkbox_list_item{
        width: 100%;
        display: flex;
        justify-content: space-between;

        span{
          display: flex;
        }
      }
     
    }
  }
}