@import './Footer.module.scss';

$header_height: var(--f64);
$menu_breakpoint: 60rem;
$leftmenu_width: var(--f224);
$collapsed_leftmenu_width: 80px;
$main_content_width: var(--f1024);
$content_padding: var(--f37);

.withSidebar {
  margin: 0;
  margin-left: $leftmenu_width;
  padding-top: $header_height;
  z-index: 0;

  &.collapsed {
    margin-left: $collapsed_leftmenu_width;
  }

  .content {
    height: calc(100vh - $header_height - $footer_height - $footer_margin);
    min-width: $main_content_width;
    padding-left: $content_padding;
    padding-right: 5rem;
    overflow-y: auto;
    position: relative;
    // padding-top: var(--f26);
  }
}

.withoutSidebar {
  width: 100%;
  padding-top: var(--header-height);

  .content {
    min-height: calc(100vh - $header_height - $footer_height - $footer_margin);
    width: 85vw;
    margin: 0 auto;
  }
}
