@import 'styles/mixin.scss';

.dropdown_pos {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9999988;
  transform: translate(-50%, -50%);

}

.menu {
  filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.2));
  background: #FFFFFF;
  border-radius: var(--f4);
  border: 0px;
}

.title {
  @include text(var(--f12),500);
  padding-left: var(--f11);
  padding-top: var(--f6);
  padding-bottom: var(--f8);

  color: var(--gray-50);
}

.search_box {
  border: 0px;
  padding-left: var(--f11);
  padding-top: var(--f6);
  padding-bottom:  var(--f8);

  &:focus {
    outline: none;
  }
}

.item {
  &:hover {
    background: var(--gray-10);
  }
}
