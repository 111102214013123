/* Day SceneList Modal */

.scenelist_container{
  width: 393px;
  padding: 10px 28px 0px 28px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: white;
  filter: drop-shadow(0px 1px 20px rgba(0, 0, 0, 0.2));
  text-align: center;
}
.scenelist_container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

.scenelist{
  width: 100%;
  max-height: 430px;
  overflow-x: hidden;
  overflow-y: auto;
}
.scenelist::-webkit-scrollbar {
  width: 6px;
  background-color: var(--white);
  border: none;
}
.scenelist::-webkit-scrollbar-thumb{
  height: 30%;
  background: #D2D7DC;
  border-radius: 4px;
}

.no_scene{
  color: var(--gray-50);
  font-size: var(--f12);
}

.place {
  text-align: left;
  display: flex;
  flex-direction: column;
  .places {
    font-weight: 700;
  }
  .content {
    color: var(--gray-50);
    font-weight: 500;
    font-size: var(--f10);
    line-height: 166%;

    &:last-child {
      pointer-events: none;
    }
  }
}
