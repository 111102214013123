
.ready_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {


  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 60px;
  /* identical to box height */

  letter-spacing: -0.04em;

  /* Text */

  color: #353A43;

}
