@import "../../../styles/mixin.scss";

.dropzone_style {
  margin-bottom: var(--f20);
}

.input {
  border-right: 0;
  height: var(--f32);
  &:disabled {
    background: var(--gray-10);
  }
}

.btn_upload_cancel{
  display: flex;
  justify-content: center;
  align-items: center;
}

.row{
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  width: 100%;
  height: var(--f32);
  margin: 0 0 var(--f13);
  border-radius: var(--f4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.control_btn_group {
  margin-top: var(--f20);
  margin-bottom: var(--f5);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--f13);
}

.item_text {
  @include text(var(--f12),500);
  color: var(--gray-50);
  margin-bottom: 0;
  margin-right: var(--f32);
}

.deletableInput {
  display: flex;
  align-items: center;

  input {
    border: 0;
    outline: 0;
    flex: 1;
    min-width: unset;
    max-width: 170px;
    background-color: transparent;
  }

}
