@import "../../styles/mixin.scss";

.label {
  @include text(var(--f14),600);
  color: var(--gray-50);
}

@mixin labelInput() {
  @include text(var(--f14), 500);
  display: flex;
  width: 100%;
  text-indent: var(--f11);
  background: var(--gray-10);
  border-radius: var(--f4);
  height: var(--f126);
  border: 0px;

  &::placeholder {
    @include text(var(--f14), 500);
    color: var(--gray-50);
  }
}

.input_container{

  .input_box {
    @include labelInput();
    height: var(--f42);
  }
}

.textarea_box {
  @include labelInput();
  height: var(--f126);
  resize: none;
}
.error_message_container{
  position:relative;
}
.error_message {
  @include text(var(--f12),500);
  color: var(--red-90);
  position:absolute;
  right:0;
  padding-right: var(--f10);
}


.label_top {
  @include text(var(--f14),600);
  color: var(--gray-50);
}
.label_top_box {
  justify-content: flex-start;
  margin-bottom: var(--f11);
  @include flexCenter();
}

.label_left_box {
  justify-content: flex-end;
  @include flexCenter();
}


.label_select {
  background-color: var(--gray-10);
}

.delete_badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 4px;
}

.badge_delete_button {
  border: none !important;
  background-color: transparent !important;
  cursor: pointer !important;
  padding: 0 !important;
  margin-left: 8px;
  margin-right: 4px;
}

.badge_container {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
}
