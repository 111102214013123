@import "../../styles/mixin.scss";

.del_file {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-size: var(--f12);
  line-height: var(--f16);
  text-decoration-line: underline;

  /* Blue Gray/300 */

  color: var(--gray-50);

  border: 0;
  background-color: white;
}

.form_input_container {
  margin-top: 2.75rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.input {
  justify-content: center;
  width: 20rem;
  margin-bottom: var(--f20);
}

.label {
  @include flexCenter();
}

.thumbnail {
  @include ImagePreview();
  width: 15.75rem;
  height: 8.125rem;
  display: inline-block;
  border-radius: 0.25rem;
}
