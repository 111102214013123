/* Date Picker*/

.calendar_header{
  display:flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--f16);
  font-weight: 500;
  color: var(--gray-90);
}

.btn_month{
  background: none;
  border: none;
  text-align: center;
}

.not_this_month{
  color: var(--gray-50);
}
