.relative { position: relative; }
.absolute { position: absolute; }
.bottom-0 { bottom: 0; }
.right-0 { right: 0; }
.start-auto { left: auto!important;}

.pointer { cursor: pointer; }
.filter-gray { filter: grayscale(1); }
.border-dashed { border-style: dashed !important; }
.aspect-square { aspect-ratio: 1/1; }

.items-center { display: flex; align-items: center; }
.items-start { display: flex; align-items: flex-start; }
.items-end { display: flex; align-items: flex-end; }
.justify-between { justify-content: space-between; }

.text-left { text-align: left; }
.text-right { text-align: right; }
.whitespace-pre { white-space: pre-wrap; }
.nowrap { white-space: nowrap; }
.text-manager { color: var(--gray-90)}

.border-bluegray-300 { border: 1px solid var(--blue-gray-300); }

.image-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.object-cover { object-fit: cover;}
.object-contain { object-fit: contain;}

.vertical-top { vertical-align: top;}

.card {
  display: flex;
  flex-direction: row;
  border: 0;

  .card-image {
    min-width: 146px;
    min-height: 80px;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
    overflow: hidden;
    background-color: #d2d7dc;
  }

  .card-content {
    border: 1px solid #d2d7dc;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
    border-left-width: 0;
    flex: 1;
    padding: 14px 15px;

    &.no-image {
      border-left-width: 1px;
      border-top-left-radius: 14px;
      border-bottom-left-radius: 14px;
    }
  }

  &.dir-col {
    flex-direction: column;

    .card-image {
      min-width: unset;
      min-height: 188px;
      height: 188px;
      border-top-left-radius: 14px;
      border-top-right-radius: 14px;
      border-bottom-left-radius: 0;
    }

    .card-content {
      border-top-right-radius: 0;
      border-bottom-left-radius: 14px;
      border-bottom-right-radius: 14px;
      border-top-width: 0;
      border-left-width: 1px;
      padding: 20px 22px;
    }
  }
}

.fc .fc-daygrid-day-number {
  width: 100%;
}

.dropdown {
  .bottom-end {
    left: auto!important;
    right: 0!important;
  }
}

.dropdown-menu {
  min-width: 5rem!important;
  width: fit-content;
}

.Toastify__toast-container {
  width: fit-content;
}
.Toastify__toast {
  border-radius: 26px;
  min-height: 50px;
  padding: 14px 30px;
}
.Toastify__toast-theme--dark {
  background: rgba(0, 0, 0, 0.80);
}
.Toastify__toast-body {
  font-family: 'Noto Sans KR';
  font-size: 16.8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.672px;
  padding: 0;
}
.Toastify__close-button {
  display: none;
}


.react-datepicker__month {
  .react-datepicker__week {
    .react-datepicker__day--in-range {
      background-color: #868FD3;
      color: white;
    }
  }
}
