.w-fit { width: fit-content; }
.w-6 { width: 1.5rem; }
.w-10 { width: 2.5rem; }
.w-15 { width: 3.75rem; }
.w-27 { width: 6.75rem; }
.w-32 { width: 8rem; }
.w-52 { width: 13rem; }
.w-60 { width: 15rem; }
.w-80 { width: 320px; }
.w-90 {width: 22.5rem;}
.w-185 {width: 740px;}

.min-w-10 {min-width: 40px;}
.min-w-15 {min-width: 60px;}
.min-w-121 {min-width: 484px;}
.min-w-130 {min-width: 520px;}

.h-8 { height: 2rem; }
.h-10 { height: 2.5rem; }
.h-10\.5 { height: 2.625rem; }
.h-20 { height: 5rem; }
.h-15 { height: 3.75rem; }
.h-90 { height: 360px; }
.min-h-100 { min-height: 100%; }

// .rounded { border-radius: 4px; }
.rounded-2 { border-radius: 8px!important; }
.rounded-5 { border-radius: 20px!important; }
.rounded-3\.5 {border-radius: 14px;}
.rounded-full { border-radius: 50%; }
