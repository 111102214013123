.root {
    border-bottom: 1px solid var(--bluegray-200);

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: flex-end;
        column-gap: 32px;

        li {
            border-bottom: 3px solid transparent;
            margin-bottom: -1px;
            cursor: pointer;
            color: #939393;
            font-weight: 700;
        }

        li.selected {
            border-color: var(--default);
            color: var(--default);
        }
    }
}