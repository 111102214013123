.calendar_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--f32);
}
.btn_arrow {
  background: none;
  border: none;
  text-align: center;
}
.toolbarLeft {
  min-width: 120px;
}
.toolbarRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 120px;
}

.calendar_week_header {
  display: flex;
  padding: 0;
  margin: 0;
  li {
    width: 181px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--gray-50);
    list-style: none;
  }
}

.calendar_cell_rows {
  border-right: 1px solid #d2d7dc;
  border-bottom: 1px solid #d2d7dc;
}
.calendar_cell_row {
  display: flex;
}

.column_top {
  width: 100%;
  height: 40px;
  position: relative;
}

.day_number {
  width: var(--f18);
  text-align: end;
  position: absolute;
  top: var(--f10);
  right: var(--f14);
}

.calendar {
  .fc-daygrid-day-number {
    width: 100%;
  }
}

