.container{
  display: flex;
  justify-content:center;
  margin-bottom: var(--f20);
}

.loading_spinner {
  position: relative;
  width: var(--f48);
  height: var(--f48);

  div {
    transform-origin: var(--f24) var(--f24);
    animation: spin 0.8s linear infinite;

    &:after {
      content: '';
      position: absolute;
      top: var(--f3);
      left: var(--f22);
      width: var(--f4);
      height: var(--f10);
      border-radius: var(--f4);
      background: var(--default);
    }

    &:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -0.7s;
    }
    &:nth-child(2) {
      transform: rotate(45deg);
      animation-delay: -0.6s;
    }
    &:nth-child(3) {
      transform: rotate(90deg);
      animation-delay: -0.5s;
    }
    &:nth-child(4) {
      transform: rotate(135deg);
      animation-delay: -0.4s;
    }
    &:nth-child(5) {
      transform: rotate(180deg);
      animation-delay: -0.3s;
    }
    &:nth-child(6) {
      transform: rotate(225deg);
      animation-delay: -0.2s;
    }
    &:nth-child(7) {
      transform: rotate(270deg);
      animation-delay: -0.1s;
    }
    &:nth-child(8) {
      transform: rotate(315deg);
      animation-delay: -0s;
    }
  }
}

@keyframes spin {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
