.pagination {
    column-gap: 4px;
    .page-item {
        min-width: 28px;
        height: 28px;
        .page-link {
            border-radius: 6px;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: -0.64px;
            line-height: 26px;
            padding: 0px 4px;
            color: #000;
            border-color: #D2D7DC;
            text-align: center;
            outline: 0!important;
            box-shadow: none!important;
        }

        &.active {
            .page-link {
                color: #fff;
                background-color: var(--sub);  
                border-color: var(--sub);
            }
        }
    }
}