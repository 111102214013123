:root {
  --bs-body-font-family: 'Noto Sans KR', sans-serif, serif, '-apple-system', 'BlinkMacSystemFont', system-ui;
  // --scroll-height: 0;
  // layout
  

  /*========== Colors ==========*/
  --default: var(--blue-80);
  --default-text: var(--gray-90);

  --blue-gray-100: #eceff2;
  --blue-gray-300: #93969B;

  --blue-40: #868fd3; //페이징, 날짜 선택
  --blue-60: #5880e7;
  --blue-70: #0073ff;
  --blue-80: #1b2ca2; //기본
  --blue-8010: rgba(27, 44, 162, 0.1);
  --blue-90: #091fa0; //진행상황

  --lightGreen-50: #3ac8b4; //주소
  --lightGreen-90: #00ffdc; //역할

  --red-30: #ff97aa;
  --red-50: #e5615d;
  --red-90: #c70a0a;

  --gray-10: #f9fafb; //스케줄 추가
  --gray-1010: #f5f5f5;
  --gray-20: #f6f6f9; //씬 스트립
  --gray-30: #d2d7dc; //테두리
  --gray-3090: #9d9b9b;
  --gray-40: #939393;
  --gray-50: #93969b; //미공유 스케줄
  --gray-90: #353a43; //텍스트

  --bluegray-200: #cccfd3; 
  --bluegray-300: #93969B; 

  --yellow-100: #ffbe17;

  --brown-90: #c78312;

  --white: #ffffff;
  --white-10: #f0f0f0;
  --white-20: #f0f0fa; //선택된 항목, 날짜
  --white-30: #e8eaed;
  --black: #000000;

  --sub: #868FD3;
  --chip-1: #3AC8B4;

  /*========== Layout ==========*/
  --header-height: 64px;
  --sidemenu-width: --f224;
  --collapsed-sidemenu_width: 80px;
  --page-nav-height: 44px;
  --page-full-height: calc(100vh - 160px);

  /*========== Font Size ==========*/
  --f1: 0.063rem;
  --f2: 0.125rem;
  --f3: 0.188rem;
  --f4: 0.25rem;
  --f5: 0.313rem;
  --f6: 0.375rem;
  --f7: 0.438rem;
  --f8: 0.5rem;
  --f9: 0.563rem;
  --f10: 0.625rem;
  --f11: 0.688rem;
  --f12: 0.75rem;
  --f13: 0.813rem;
  --f14: 0.875rem;
  --f16: 1rem;
  --f17: 1.063rem;
  --f18: 1.125rem;
  --f19: 1.188rem;
  --f20: 1.25rem;
  --f22: 1.375rem;
  --f24: 1.5rem;
  --f25: 1.563rem;
  --f26: 1.625rem;
  --f28: 1.75rem;
  --f30: 1.875rem;
  --f32: 2rem;
  --f34: 2.125rem;
  --f36: 2.25rem;
  --f37: 2.313rem;
  --f40: 2.5rem;
  --f42: 2.625rem;
  --f43: 2.688rem;
  --f45: 2.813rem;
  --f46: 2.875rem;
  --f48: 3rem;
  --f50: 3.125rem;
  --f52: 3.25rem;
  --f54: 3.375rem;
  --f55: 3.438rem;
  --f60: 3.75rem;
  --f64: 4rem;
  --f70: 4.375rem;
  --f72: 4.5rem;
  --f80: 5rem;
  --f90: 5.625rem;
  --f94: 5.875rem;
  --f100: 6.25rem;
  --f110: 6.875rem;
  --f126: 7.875rem;
  --f136: 8.5rem;
  --f160: 10rem;
  --f172: 10.75rem;
  --f190: 11.875rem;
  --f196: 12.25rem;
  --f200: 12.5rem;
  --f204: 12.75rem;
  --f210: 13.125rem;
  --f223: 13.938rem;
  --f224: 14rem;
  --f240: 15rem;
  --f250: 15.625rem;
  --f260: 16.25rem;
  --f290: 18.125rem;
  --f300: 18.75rem;
  --f310: 19.375rem;
  --f320: 20rem;
  --f338: 21.125rem;
  --f478: 29.875rem;
  --f482: 30.125rem;
  --f493: 30.813rem;
  --f514: 32.125rem;
  --f522: 32.625rem;
  --f613: 38.313rem;
  --f650: 40.625rem;
  --f742: 46.375rem;
  --f820: 51.25rem;
  --f878: 54.875rem;
  --f1000: 62.5rem;
  --f1024: 64rem;
  --f1200: 75rem;

  /*========== Font Weights ==========*/
  --lighter: 100;
  --light: 300;
  --normal: 400;
  --bold: 700;
  --bolder: 900;
}
