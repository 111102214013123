
.frame {
  text-align: center;
  font-family: 'Noto Sans';
  font-style: normal;

  h3 {
    margin-top: calc(-1 * var(--f20));
    font-size: var(--f24);
    line-height: 166%;
    letter-spacing: -0.04em;
    margin-bottom: 0;
    font-weight: 500;

    /* #353A43 */
    color: #353A43;
  }

  small {
    color: #93969C;
    font-size: var(--f16);
    font-weight: 300;
  }
}
