.form-control,
.form-select {
  box-shadow: none !important;
  outline: 0;
  font-size: 14px;

  &.input-gray {
    border: 1px solid #d2d7dc;
    background-color: #f9fafb;
  }
}

.form-control::placeholder {
  color: var(--blue-gray-300);
}

.input-group-text {
  padding-left: 5px;
  padding-right: 10px;
}

.input-group.no-split {
  .form-control {
    border-right-width: 0 !important;
  }

  .form-control:active,
  .form-control:focus {
    outline: 0 !important;
    border-color: var(--bs-border-color) !important;
  }

  .input-group-text {
    background-color: white;
  }
}

.input-group.input-sm {
  textarea.form-control {
    height: auto;
  }

  .form-control,
  .input-group-text,
  .form-select {
    height: 32px;
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 20px;
    font-size: 12px;
  }
}

.input-group.no-border {
  .form-control,
  .input-group-text,
  .form-select {
    border: 0;
  }
}
