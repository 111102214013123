.menu_group {
  display: flex;

  .menu {
    margin-right: var(--f26);
    color: var(--gray-50);
    font-weight: var(--bold);
    font-size: var(--f20);
    line-height: 166%;
    cursor: pointer;
  }

  .menu_active {
    margin-right: var(--f26);
    color: var(--default);
    font-weight: var(--bold);
    font-size: var(--f20);
    line-height: 166%;
    cursor: pointer;
  }

  .menu_underline {
    height: var(--f3);
    background-color: var(--default);
    transform: translateY(1.1rem);
  }
}

.upper_menu {
  display: flex;
  justify-content: space-between;
}
