.btn {
    box-sizing: border-box;
}

.btn-outline-dark {
    --bs-btn-hover-bg: white;
    --bs-btn-hover-color: $dark;
    --bs-btn-active-color: $dark;
    --bs-btn-active-bg: white;

    &:hover {
        opacity: 0.5;
    }
}

.btn-outline-gray {
    --bs-btn-hover-bg: white;
    --bs-btn-hover-color: #353A43;
    --bs-btn-active-color: #353A43;
    --bs-btn-active-bg: white;
    --bs-btn-border-color: #D2D7DC;
    --bs-btn-hover-border-color: #D2D7DC;
}

.btn-dark-gray {
  --bs-btn-bg: #353A43;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-color: #353A43;
  --bs-btn-active-color: #353A43;
  --bs-btn-active-bg: white;
  --bs-btn-border-color: #D2D7DC;
  --bs-btn-hover-border-color: #D2D7DC;
}

.btn-outline-dark-gray {
  --bs-btn-bg: white;
  --bs-btn-hover-bg: #353A43;
  --bs-btn-hover-color: white;
  --bs-btn-active-color: #353A43;
  --bs-btn-active-bg: white;
  --bs-btn-border-color: #D2D7DC;
  --bs-btn-hover-border-color: #D2D7DC;
}

.btn-white {
    --bs-btn-bg: white;
    --bs-btn-hover-bg: white;
    --bs-btn-active-bg: white;
    --bs-btn-border-color: white;
    --bs-btn-hover-border-color: white;
}

.btn-gray {
    --bs-btn-bg: #d2d7dc;
    --bs-btn-hover-bg: #d2d7dc;
    --bs-btn-active-bg: #d2d7dc;
    --bs-btn-border-color: $blue-gray-200;
    --bs-btn-hover-border-color: $blue-gray-200;
}

.bs-20px {
    width: 20px;
    height: 20px;
}
