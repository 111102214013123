// Color system

$white: #fff;
$gray-100: #f9fafb;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #5b626b;
$gray-800: #353a43;
$gray-900: #212529;
$black: #000;

$grays: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
  ),
  $grays
);

$blue: #7a6fbe;
$blue-10: #f0f0fa;
$blue-40: #868fd3;
$blue-60: #5880e7;
$blue-70: #0073ff;
$blue-80: #1b2ca2;
$blue-8010: rgba(27, 44, 162, 0.1);
$blue-90: #091fa0;
$indigo: #564ab1;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #ec536c;
$red-30: #ff97aa;
$red-50: #e5615d;
$red-90: #c70a0a;
$orange: #f1734f;
$yellow: #f5b225;
$yellow-100: #ffbe17;
$green: #16b55f;
$green-50: #3ac8b4; //주소
$green-90: #00ffdc; //역할
$teal: #050505;
$cyan: #29bbe3;
$brown-90: #c78312;
$blue-gray-100: #f9fafb;
$blue-gray-200: #d2d7dc;
$blue-gray-300: #93969b;
$blue-gray-400: #6e6f73;
$chip-1: #3ac8b4;
$sub: #868fd3;
$badge-disable: #e8eaed;
$badge-accept: #e6f7f6;

$colors: ();
// // stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    'blue': $blue,
    'blue-10': $blue-10,
    'blue-40': $blue-40,
    'blue-60': $blue-60,
    'blue-70': $blue-70,
    'blue-80': $blue-80,
    'blue-8010': $blue-8010,
    'blue-90': $blue-90,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'red-30': $red-30,
    'red-50': $red-50,
    'red-90': $red-90,
    'orange': $orange,
    'yellow': $yellow,
    'yellow-100': $yellow-100,
    'green': $green,
    'green-50': $green-50,
    'green-90': $green-90,
    'teal': $teal,
    'cyan': $cyan,
    'brown-90': $brown-90,
    'white': $white,
    'gray': $gray-600,
    'gray-dark': $gray-800,
    'badge-disable': $badge-disable,
    'badge-accept': $badge-accept,
  ),
  $colors
);

$primary: $blue-80;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'pink': $pink,
    'light': $light,
    'dark': $dark,
    'blue-gray-100': $blue-gray-100,
    'blue-gray-200': $blue-gray-200,
    'blue-gray-300': $blue-gray-300,
    'blue-gray-400': $blue-gray-400,
    'chip-1': $chip-1,
    'red-50': $red-50,
    'blue-10': $blue-10,
    'blue-80': $blue-80,
    'blue-8010': $blue-8010,
    'sub': $sub,
    'badge-disable': $badge-disable,
    'badge-accept': $badge-accept,
    'grey-800': $gray-800,
  ),
  $theme-colors
);

// Font Family
// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Noto Sans', 'Noto Sans KR', 'Gowun Batang', -apple-system, BlinkMacSystemFont, system-ui,
  sans-serif, serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;
// stylelint-enable value-keyword-case

$page-nav-height: 44px;
$custom-spacers: (
  3: $spacer * 0.75,
  4: $spacer * 1,
  5: $spacer * 1.25,
  6: $spacer * 1.5,
  7: $spacer * 1.75,
  8: $spacer * 2,
  9: $spacer * 2.25,
  10: $spacer * 2.5,
  11: $spacer * 2.75,
  12: $spacer * 3,
  13: $spacer * 3.25,
  14: $spacer * 3.5,
  15: $spacer * 3.75,
  16: $spacer * 4,
  100: $spacer * 6,
  136: $spacer * 8.5,
) !default;
