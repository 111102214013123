.carouselItem {
  background-color: #d2d7dc;
  height: 260px;
  border-radius: 14px;
  overflow: hidden;

  &.fake {
    display: flex;
    justify-content: center;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.carouselControl {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  padding: 8px!important;
  border-radius: 50%!important;
  width: 44px;
  height: 44px;
  background-color: white !important;
  border-color: white !important;
}

.carouselControlLeft {
  left: 20px;
}

.carouselControlRight {
  right: 20px;
}
