@import "styles/mixin.scss";

.input_container{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--f11);
  border: 0;
  background: var(--gray-10);
  border-radius: var(--f4);
  transition: 0.3s;
  width: 100%;
  height: var(--f42);

  &:after {
    content: none;
  }
}

.input {
  @include text(var(--f14), 500);
  color: var(--gray-90);
  border:none!important;
  outline: 0!important;
  background: none;
  display:flex;
  align-items: center;
  width: 100%;
  
  &::placeholder {
    font-family: inherit;
    font-size: inherit;
    color: var(--gray-50);
    display:flex;
    align-items: center;
  }
}

.label {
  @include text(var(--f14),600);
  color: var(--gray-90);
  padding:0;
}

.dropdown_input{
  position: relative;
}

.error_message {
  @include text(var(--f12),500);
  color: var(--red-90);
  position:absolute;
  right:0;
  padding-right: var(--f10);
}

.dropdown_container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label_top_box {
  justify-content: flex-start;
  margin-bottom: var(--f11);
  @include flexCenter();
}

.label_left_box {
  justify-content: flex-end;
  @include flexCenter();
}
