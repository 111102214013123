.container {
  display: flex;
  gap: 10px;
  padding-right: 10px;
}

.icon_button {
  background-color: white !important;
  border: var(--f1) solid var(--gray-30);
  border-radius: var(--f8);
  width: var(--f40);
  height: var(--f40);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: var(--f10);
  padding: 0 !important;
  border: 0 !important;

  &:hover {
    opacity: 0.2;
    background-color: white;
  }
}

.icon_button_only_icon {

  width: var(--f40);
  height: var(--f40);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  padding: 0;

  &:focus {
    outline: none;
    box-shadow: none;
    background: none;
  }

  &:hover {
    background: none;
    border: none;
    opacity: 0.2;
  }
}

.btnWrap {
  position: relative;

  .tooltip {
    opacity: 0;
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    background-color: black;
    color: white;
    white-space: nowrap;
    z-index: -1;
    padding: 4px 8px;
    border-radius: 6px;
  }

  .arrow {
    position: absolute;
    width: 12px;
    height: 6px;
    z-index: -1;
    border-width: 6px 6px 0 6px;
    border-color: transparent;
    border-top-color: black;
    display: block;
    border-style: solid;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
  }

  &:hover {
    .tooltip {
      opacity: 1;
      z-index: 10;
    }

    .arrow {
      opacity: 1;
      z-index: 10;
    }
  }
}