@import 'styles/mixin.scss';

.episode_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: var(--f4) var(--f24);
  gap: var(--f10);

  height: 31px;

  /* primary */

  background: var(--white);
  border-radius: 0px 0px var(--f8) var(--f8);
  border: 1px solid var(--gray-30);

  span {
    @include text(var(--f16),500);
    text-align: center;
    vertical-align: center;
    color: var(--black);
  }

  &:hover {
    opacity: 0.8;
  }
}

.episode_button_active {
  background: var(--default);
  span {
    color: var(--white);
  }
}
.episode_button_text{
  white-space: nowrap;
}

.change_text_input{
  @include text(var(--f16),500);
  display: inline-block;
  max-width: var(--f110);

}

.episode_move_button_group {
  box-sizing: border-box;

  display: inline-flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding-right: var(--f17);
  padding-left: var(--f17);

  width: 132px;
  height: 31px;

  background: var(--white);

  border: 1px solid var(--gray-30);
  border-radius: 0 0 var(--f8) var(--f8);
  button {
    /* Auto layout */
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: var(--f14);
    height: var(--f14);

    background: var(--white);
    border: 0;

    &:hover {
      opacity: 0.6;
    }
  }
}


/* Square Plus Button */
.btn_plus{
  padding: 0;
  width: var(--f18);
  height: var(--f18);
  border: 1px solid var(--gray-30);
  border-radius: var(--f4);
  box-sizing: border-box;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 4px;
  transition: all .5s;
}

.btn_plus:hover{
  transform: scale(1.1);
  background-color: white;
}

/* Buttons - cancel, save button */

.default_button{
  @include text(var(--f14),700);
  height: var(--f40);
  
  display:flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--f8);
  padding: var(--f10) var(--f14);
  gap: var(--f8);

  &:hover{
    opacity: 0.2;
  }

  &.btn_white {
    border: var(--f1) solid var(--gray-30);
    color: var(--gray-90);
    background-color: var(--white);
  }
  
  &.btn_default {
    border:none;
    color: var(--white);
    background-color: var(--default);
  }
  
  &.form{
    padding: var(--f10) var(--f34);
  }
}


