@import 'styles/mixin.scss';

/* Banner Modal */

.banner_content{
  margin-top: var(--f10);
  display: flex;
  flex-direction: column;
  gap: var(--f5);
  text-align: left;

  input{
    margin: var(--f10) 0;
  }
}
.duration_input{
  width: 100%;
  background-color: var(--gray-10);
  border-radius: var(--f4);
  font-size: var(--f12);
  font-weight: 500;

  color : var(--blue-80);
  padding-left: var(--f10);

  .duration {
    width: var(--f40);
    border: none;
    background: none;
    outline: 0;
  }
}

/* Add HeadStrip Modal */
.section{
  display: flex;
  flex-direction: column;
  gap: var(--f7);
  text-align: left;
}
.time{
  gap: var(-f12);
}

/*Add Schedule Group Modal */
.add_schedule_input{
  display: flex;
  flex-direction: column;
  gap: var(--f10);
}

/* Scene Filtering Modal*/
.search_lists{
  display: flex;
  gap: var(--f10);
}
.preview{
  padding: 0 var(--f10);
  margin-top: var(--f30);
}