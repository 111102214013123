@import '../../styles/mixin.scss';

.tooltip {
  pointer-events: none;
  animation: fadeIn 0.3s 1;
  transition: 0.1s;
}

.tooltip_text{
  overflow: hidden;
  white-space: nowrap;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
