@import "../../../styles/mixin.scss";

.body {
  height: 75vh;
}

.container{
  display: flex;
  flex-direction: column;
  padding: 1vh;
  gap: 2vh;
}
.row_top{
  display: flex;
  justify-content: space-between;
}
.row_bottom{
  display: flex;
  align-items: center;
}

.button_group{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;

  .big_button{
    height: 30%;
  }
}

.search_list{
  padding:0;
  padding-left: var(--f20);
}

/* SceneStatisticChart */
.chart_container{
  padding: var(--f20) var(--f24);
  background-color: var(--gray-10);
  border-radius: var(--f8);
  font-size: var(--f14);
}
.project_title{
  @include text(var(--f16),600);
  color: var(--gray-3090);
  padding-left: var(--f20);
}

.chart_box{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: var(--f10);
}

.eclipse{
  width:var(--f10);
  height:var(--f10);
  border-radius: 50%;
}
.line{
  margin:var(--f14);
}

@media screen and (max-height: 860px) {
  .chart_container{
    padding: 1vh var(--f24);
    font-size: var(--f12);
  }
  .line{
    margin:var(--f10) 0;
  }
}