.fc a{
  text-decoration: none;
  color: #353A43;
}

.fc th {
  text-align: center;
  border: none;
}

.fc-scroller-liquid-absolute::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

.fc-theme-standard .fc-scrollgrid {
  border-top: none;
  border : none;
}


.fc .fc-col-header-cell-cushion { /* thead (월,화,수,목,금,토,일) */
  display: inline-block; /* x-browser for when sticky (when multi-tier header) */
  padding: 2px 4px;
  font-family: 'Noto Sans KR';
  font-weight: 500;
  font-size: var(--f18);
  color: #93969B;
  margin-bottom: 10px;
}

.fc .fc-scroller-liquid-absolute { /* tbody */
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border-top : 1px solid #ddd;
  border-left : 1px solid #ddd;
}

.fc-day-sun,.fc-day-sat {
  background: #F9FAFB;
}

.fc-col-header-cell{
  background : white;
}

.fc-daygrid-event { /* allowed to be top-level */
  border: 1px solid var(--fc-event-border-color, var(--lightGreen-50));
  background-color: var(--fc-event-bg-color, var(--lightGreen-50));
  z-index: auto !important;
}

.fc-event-main{
  z-index: auto !important;
}

.btn_plus{
  display: none;
  position: absolute;
  top: var(--f10);
  left: 0;
}

.fc-daygrid-day-frame:hover .btn_plus{
  display: flex;
  animation-duration: 1s;
  animation-name: fadeout;
}

@keyframes fadeout {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
  

