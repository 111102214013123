.table {
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 8px;
  }

  thead tr {
    th {
        padding: 7px 4px;
    }
  }

  tbody tr {
    td {
        background-color: #f1f1f1;
        padding: 7px 4px;
    }

    td:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    td:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
  }
}

.table_spacing {
  table {
    border-spacing: 8px 8px!important;
  }
}
