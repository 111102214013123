.root {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 2px solid var(--gray-30);
  max-height: var(--page-nav-height);
  height: var(--page-nav-height);
  z-index: 99;
  position: relative;
}

.noMenu {
  height: 0px!important;
}

.menu {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;

  .item {
    margin-right: var(--f26);
    margin-bottom: -2px;
    padding-bottom: 8px;
    padding-top: 0px;
    color: var(--gray-50);
    font-weight: var(--bold);
    font-size: var(--f20);
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: transparent;
    user-select: none;
    cursor: pointer;
  }

  .itemActive {
    color: var(--default);
    border-bottom-color: var(--default);
  }
}

.managerMenu {
  composes: menu;

  .itemActive {
    color: var(--gray-90);
    border-bottom-color: var(--gray-90);
  }
}

.addon {
  padding-bottom: 28px;
}

.onlyAddon {
  padding-bottom: 20px!important;
}
