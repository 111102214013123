@mixin hideScrollBar(){
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
}

@mixin ellipsis(){
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

@mixin text($size,$weight){
  font-family: Noto Sans KR;
  font-weight: $weight;
  font-size: $size;
  font-style: normal;
  line-height:166%;
  letter-spacing: -0.04rem;
}

@mixin flexCenter() {
  display: flex;
  align-items: center;
}

@mixin ImagePreview() {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
