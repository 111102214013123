
@import "../../../styles/mixin.scss";

.button_group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1vw;
}

.container{
  display: flex;
  flex-direction: column;
  gap: 2vh;
}
.row{
  margin-left: var(--f60);
}
.form{
  gap: var(--f20);
}