.root {
  height: 96px;
  padding: 26px 0 16px 0!important;
  column-gap: 12px;
}

.link {
    color: var(--gray-90);
    text-decoration: none;
}
