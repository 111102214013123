@import "styles/mixin.scss";

.badge {
  display: flex;
  align-items: center;
  column-gap: 4px;
  padding: 2px 14px;
  align-items: center;
  justify-content: center;
  background: #E8EAED;
  border-radius: 20px;
  white-space: nowrap;

  @include text(var(--f14), 700);
  color: #CE9837;
}
