%modal_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background: #091FA0;
  border-radius: 8px;
  border: 0px;

  span {
    text-align: center;
    vertical-align: center;

    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    margin: 12px 34px;

    color: #FFFFFF;
  }

  &:hover {
    opacity: 0.8;
  }
}


/*Modify Access Modal*/
.search_user{
  width:100%;
  display:flex;
  margin-bottom: var(--f26);

  input{
    margin-right: var(--f14);
  }

  button{
    @extend %modal_button;
    width: var(--f110);
    color:var(--white);
    background-color: var(--gray-90);
    font-size: var(--f14);
  }
}

.access_modal__user_group{
  width:100%;
  display:flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: var(--f240);
  padding: 0;

  li{
    width:100%;
    display:flex;
    justify-content: space-between;
    align-items:center;
    list-style: none;
    padding: var(--f7) var(--f20);
    background-color: var(--gray-10);
    border-radius: var(--f4);

    &:not(:first-of-type){
      margin-top: var(--f8);
    }
  }
  .profile{
    width: var(--f40);
    border-radius: 50%;
    margin-right: var(--f14);
  }

  .name{
    font-weight: 700;
    font-size: var(--f14);
  }

  .access_state{
    span{
      font-size: var(--f14);
      color: var(--gray-50);
    }

    button{
      background: none;
      border: none;
      margin-left: var(--f16);
    }
  }
}
