@import 'styles/mixin.scss';
@import '../../global.scss';

$footer_height: 5rem;
$footer_margin: 1rem;

.footer {
  @include flexCenter();
  @include text(0.8rem, 500);
  justify-content: space-around;
  margin: $footer_margin $footer_margin;
  padding: 1rem 0rem;
  border-top: 1px solid var(--gray-30);

  .section1 {
    @include flexCenter();
    @include text(1rem, 500)
  }

  .section2 {
    @include flexCenter();
    gap: 2rem;
  }

  .section3 {
    @include flexCenter();
    gap: 1rem;
  }

  a {
    color: $gray-600;
    text-decoration: none;
  }

  div {
    color: $gray-600;
  }

  img {
    margin-right: 0.3rem;
  }
}
