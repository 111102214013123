
#error_page {
  text-align: center;
  margin-top: 10rem;

  .image {
    width: 18rem;
  }

  .title {
    width: fit-content;
    margin: auto;
    color: var(--gray-90);
    font-size: var(--f24);
    font-weight: 500;
    line-height: 166%;
  }

  .main {
    width: fit-content;
    margin: 0 auto 5.5rem;
    color: var(--gray-50);
    line-height: 166%;
  }

  .button {
    padding: var(--f8) var(--f14);
    background-color: var(--default);
    border-radius: var(--f8);
    border: none;
    font-weight: 500;
    font-size: var(--f16);
    line-height: var(--f22);
  }
}