.body {
  font-size: 14px;
  line-height: 166%;
  letter-spacing: -0.56px;
}
.f10_normal__40 {
  font-size: 10px;
  line-height: 14px;
  letter-spacing: -0.4px;
}
.f10_166__40 {
  font-size: 10px;
  line-height: 166%;
  letter-spacing: -0.4px;
}

.f12_normal__48 {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.48px;
}

.f12_normal__48__shadow {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.48px;
  text-shadow:
    1px 1px 0 white,
    -1px -1px 0 white,
    -1px 1px 0 white,
    1px -1px 0 white,
    1px 0px 0 white,
    0px 1px 0 white,
    -1px 0px 0 white,
    0px -1px 0 white;
}

.f12_140_normal {
  font-size: 12px;
  line-height: 140%;
}

.f12_166__48 {
  font-size: 12px;
  line-height: 166%;
  letter-spacing: -0.48px;
}

.f14_normal__56 {
  font-size: 14px;
  line-height: normal;
  letter-spacing: -0.56px;
}

.f14_166__56 {
  font-size: 14px;
  line-height: 166%;
  letter-spacing: -0.56px;
}

.f15_normal {
  font-size: 15px;
  line-height: normal;
}

.f16_140_normal {
  font-size: 16px;
  line-height: 140%;
}

.f16_166__64 {
  font-size: 16px;
  line-height: 166%;
  letter-spacing: -0.64px;
}

.f18_normal__72 {
  font-size: 18px;
  letter-spacing: -0.72px;
}

.f18_166__72 {
  font-size: 18px;
  line-height: 166%;
  letter-spacing: -0.72px;
}

.f20_140__40 {
  font-size: 20px;
  line-height: 140%;
  letter-spacing: -0.4px;
}

.f20_166__80 {
  font-size: 20px;
  line-height: 166%;
  letter-spacing: -0.8px;
}

.f24_166__96 {
  font-size: 24px;
  line-height: 166%;
  letter-spacing: -0.96px;
}

.f32_normal__64 {
  font-size: 32px;
  letter-spacing: -0.64px;
}

.f32_166__128 {
  font-size: 32px;
  line-height: 166%;
  letter-spacing: -1.28px;
}

.f40_166__160 {
  font-size: 44px;
  line-height: 166%;
  letter-spacing: -1.6px;
}

.f44_166__176 {
  font-size: 44px;
  line-height: 166%;
  letter-spacing: -1.76px;
}
